<template>
  <div id="app">
    <component
      ref="modalComponent"
      :is="currentComponent"
      :token="token"
      :customerID="customer_id"
      :booked_event_link="booked_event_link"
      @login="login"
    />
  </div>
</template>

<script>
import Index from './components/Index.vue'
import LoginComponent from './components/LoginComponent.vue'
/* eslint-disable */
export default {
  name: 'App',
  components: {
    Index,
    LoginComponent,
  },
  data() {
    return {
      customer_id: localStorage.getItem('customer_id'),
      data: {},
      category: '',
      presense: {},
      pubnub_status: null,
      verification: false,
      token: '',
      currentComponent: null,
      booked_event_link: '',
    }
  },
  beforeMount() {
    var current_url = window.location.href
    var substr = ''

    const queryParams = this.$route.query
    substr = queryParams.token
    this.booked_event_link = queryParams.booked_event

    console.log(this.booked_event_link, 'queryParams')
    if (substr) {
      // check if url has token provided
      this.verifyToken(substr)
    } else {
      if (localStorage.getItem('token')) {
        this.use_region = this.selected_region = this.region = localStorage.getItem(
          'region',
        )

        this.token = localStorage.getItem('token')
        this.verification = true
        this.currentComponent = Index
      } else {
        this.verification = false
        this.currentComponent = LoginComponent
      }
    }
  },

  methods: {
    async checkToken(data) {
      this.use_region = this.selected_region = this.region = localStorage.getItem(
        'region',
      )
      this.verification = true
      this.token = localStorage.getItem('token')
      this.currentComponent = Index
    },
    verifyToken(token) {
      let url = process.env.VUE_APP_API_URL + '/api/auth/login'

      this.axios
        .get(url, {
          params: {
            token: token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch('assignCustomer', response.data)
            if (!localStorage.getItem('region')) {
              localStorage.setItem('region', response.data.customer.use_region)
              this.use_region = this.selected_region = this.region =
                response.data.customer.use_region
            } else {
              this.use_region = this.selected_region = this.region = localStorage.getItem(
                'region',
              )
            }

            localStorage.setItem('token', response.data.app_session.session_key)

            this.token = localStorage.getItem('token')
            this.verification = true
            this.currentComponent = Index
          } else {
            this.verification = false
            this.currentComponent = LoginComponent
            this.clearSession()
          }
        })
        .catch((err) => {
          if (localStorage.getItem('token')) {
            this.use_region = this.selected_region = this.region = localStorage.getItem(
              'region',
            )
            this.token = localStorage.getItem('token')
            this.verification = true
            this.currentComponent = Index
          } else {
            this.verification = false
            this.currentComponent = LoginComponent
          }
        })
    },
    login(data) {
      localStorage.removeItem('token')
      localStorage.setItem('token', data.app_session.session_key)
      this.token = data.app_session.session_key
      this.checkToken(data)
    },
    getFormatedDate(date) {
      var d = new Date(date)
      var month = d.toLocaleString('default', {
        month: 'short',
      })
      var time = d.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      var this_date = d.getDate()
      var dateExt = this.getDateExt(this_date)

      var formated_date = ''
      formated_date = this_date + dateExt + ' ' + month + ' ' + time
      return formated_date
    },
    getDateExt(date) {
      if (date > 3 && date < 21) return 'th'
      switch (date % 10) {
        case 1:
          return 'st'
        case 2:
          return 'nd'
        case 3:
          return 'rd'
        default:
          return 'th'
      }
    },
  },
}
</script>

<style>
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
