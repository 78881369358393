var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-panel-wrapper"},[_c('el-col',{staticStyle:{"padding":"0 20px"},attrs:{"span":24}},[_c('el-calendar',{scopedSlots:_vm._u([{key:"dateCell",fn:function({ data }){return [_c('div',{staticClass:"calendar-date",on:{"click":function($event){return _vm.getDate(data)}}},[_vm._v(" "+_vm._s(data.day.split('-').slice(2).join('-'))+" "),_c('div',{staticStyle:{"display":"block","width":"100%","margin-top":"-7px"}},_vm._l((_vm.getDots(data)),function(dot,index){return _c('span',{key:index,staticClass:"dot"})}),0)])]}}]),model:{value:(_vm.calendar_date),callback:function ($$v) {_vm.calendar_date=$$v},expression:"calendar_date"}})],1),(_vm.list_inprogress.length !== 0)?_c('el-col',{staticClass:"in-progress-bookings-container",staticStyle:{"padding":"0 20px"},attrs:{"span":24}},[_c('h4',{staticStyle:{"font-size":"14px","font-weight":"600"}},[_vm._v("In Progress  ")]),_vm._l((_vm.list_inprogress),function(event,i){return _c('el-col',{key:i,staticStyle:{"margin-bottom":"8px"},attrs:{"span":24}},[_c('div',{staticClass:"events-box",class:[
          !_vm.isInProgress(event)
            ? ''
            : [_vm.readyToJoinAnimation(i, event), 'join-now-bg'],
        ],on:{"click":function($event){return _vm.getBookingDetails(event)}}},[_c('el-col',{staticClass:"el-col-xl-3 el-col-lg-4 el-col-md-4",attrs:{"span":4}},[_c('country-flag',{staticStyle:{"float":"left","/* margin":"-1em 0.1em 0.1em -1em !important","*/\n              padding":"0","transform":"scale(0.4)","text-shadow":"0 0 #fff"},attrs:{"country":event.event_region === 'uk' ? 'gb' : event.event_region,"size":"small"}}),_c('el-tooltip',{staticClass:"item speaker-icon",attrs:{"content":event.speaker ? event.speaker.name : 'Smartcharts',"placement":"top","effect":"light"}},[_c('el-avatar',{staticClass:"speaker-avatar-circle",staticStyle:{"margin-left":"-10px","margin-top":"0.2em","position":"static"},attrs:{"size":35,"src":require(`@/assets/images/speakers/${
                  event.speaker ? event.speaker.avatar : 'smartcharts.png'
                }`)}})],1)],1),_c('el-col',{staticClass:"el-col-xl-21 el-col-lg-20 el-col-md-20",staticStyle:{"margin-top":"0.3rem"},attrs:{"span":20}},[_c('div',{staticClass:"bookings-title"},[_vm._v(" "+_vm._s(_vm.eventFullName(event))+" ")]),_c('div',{staticClass:"bookings-sub-title"},[_vm._v(" "+_vm._s(event.is_series && !_vm.isInProgress(event) ? 'Session resumes at ' : '')+" "+_vm._s(_vm.getFormatedLocalTime(event))+" "),(event.status === 'Progress')?_c('el-badge',{staticClass:"item",attrs:{"value":"Pending"}}):_vm._e()],1)])],1)])})],2):_vm._e(),_c('el-col',{staticClass:"upcoming-bookings-container",staticStyle:{"padding":"0 20px"},attrs:{"span":24}},[_c('h4',{staticStyle:{"font-size":"14px","font-weight":"600"}},[_vm._v(" My Upcoming Bookings   "),_c('span',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"#2D2953"}})]),(_vm.list_upcoming.length === 0)?_c('div',{staticClass:"no-booking-caption"},[_vm._v(" No bookings to show ")]):_vm._e(),_vm._l((_vm.list_upcoming),function(event,i){return _c('el-col',{key:i,staticStyle:{"margin-bottom":"8px"},attrs:{"span":24}},[_c('div',{staticClass:"events-box",on:{"click":function($event){return _vm.getBookingDetails(event)}}},[_c('el-col',{staticClass:"el-col-xl-3 el-col-lg-4 el-col-md-4",attrs:{"span":4}},[_c('country-flag',{staticStyle:{"float":"left","/* margin":"-1em 0.1em 0.1em -1em !important","*/\n              padding":"0","transform":"scale(0.4)","text-shadow":"0 0 #fff"},attrs:{"country":event.event_region === 'uk' ? 'gb' : event.event_region,"size":"small"}}),_c('el-tooltip',{staticClass:"item speaker-icon",attrs:{"content":event.speaker ? event.speaker.name : 'Smartcharts',"placement":"top","effect":"light"}},[_c('el-avatar',{staticClass:"speaker-avatar-circle",staticStyle:{"margin-left":"-10px","margin-top":"0.2em","position":"static"},attrs:{"size":35,"src":require(`@/assets/images/speakers/${
                  event.speaker ? event.speaker.avatar : 'smartcharts.png'
                }`)},on:{"error":_vm.errorHandler}})],1)],1),_c('el-col',{staticClass:"el-col-xl-21 el-col-lg-20 el-col-md-20",staticStyle:{"margin-top":"0.3rem"},attrs:{"span":20}},[_c('div',{staticClass:"bookings-title"},[_vm._v(" "+_vm._s(_vm.eventFullName(event))+" ")]),_c('div',{staticClass:"bookings-sub-title"},[_vm._v(" "+_vm._s(_vm.getFormatedLocalTime(event))+" "),(event.status === 'Progress')?_c('el-badge',{staticClass:"item",attrs:{"value":"Pending"}}):_vm._e()],1)])],1)])})],2),_c(_vm.currentComponent,{tag:"component",attrs:{"date":_vm.date,"event_on_this_day":_vm.event_on_this_day,"my_events_for_today":_vm.my_events_for_today,"selected_booking":_vm.selected_booking},on:{"close":function($event){return _vm.CloseModal()},"cancel_event":_vm.handleCancelEvent}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }