import { render, staticRenderFns } from "./RedirectModal.vue?vue&type=template&id=70289901&scoped=true"
import script from "./RedirectModal.vue?vue&type=script&lang=js"
export * from "./RedirectModal.vue?vue&type=script&lang=js"
import style0 from "./RedirectModal.vue?vue&type=style&index=0&id=70289901&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70289901",
  null
  
)

export default component.exports