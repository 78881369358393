import { render, staticRenderFns } from "./EventModal.vue?vue&type=template&id=bbdc6122&scoped=true"
import script from "./EventModal.vue?vue&type=script&lang=js"
export * from "./EventModal.vue?vue&type=script&lang=js"
import style0 from "./EventModal.vue?vue&type=style&index=0&id=bbdc6122&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbdc6122",
  null
  
)

export default component.exports