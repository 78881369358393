var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{staticClass:"upcoming-booking-modal",attrs:{"title":"","visible":_vm.dialogVisible,"width":"25%","top":"3%","before-close":_vm.handleClose,"modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',{staticClass:"dialog-footer",staticStyle:{"font-size":"12px"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-row',{attrs:{"gutter":20,"type":"flex","justify":"space-between"}},[_c('el-col',{staticClass:"gray-text",staticStyle:{"text-align":"left"},attrs:{"span":_vm.can_join_booking ? 16 : 8}},[_c('div',{staticClass:"grid-content"},[_c('small',[_vm._v("Booking ID:")]),_vm._v(" "+_vm._s(_vm.selected_booking.id)+" ")])]),_c('el-col',{staticClass:"gray-text",style:({ 'text-align': _vm.can_join_booking ? 'right' : 'left' }),attrs:{"span":_vm.can_join_booking ? 8 : 4}},[_c('div',{staticClass:"grid-content"},[_c('small',[_vm._v("Date:")]),_vm._v(" "+_vm._s(_vm.getFormatedDate(_vm.selected_booking.start_date))+" ")])]),(!_vm.can_join_booking)?_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"span":8}},[_c('div',{staticClass:"grid-content"},[_c('el-button',{staticClass:"cancel-booking",attrs:{"type":"danger","plain":""},on:{"click":function($event){return _vm.cancelBooking(_vm.selected_booking)}}},[_c('small',[_vm._v("Cancel "),_c('br'),_vm._v("Booking")])])],1)]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-card',{attrs:{"body-style":{ padding: '0px', background: 'transparent' }}},[_c('img',{staticStyle:{"display":"none"},attrs:{"src":_vm.default_banner}}),_c('div',{staticClass:"upcoming-booking-image-wrapper",style:({
            'background-image':
              'linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.3)) , url(' +
              _vm.default_banner +
              ')',
          })},[_c('span',{staticStyle:{"font-size":"14px","font-weight":"600","padding":"5px 10px","display":"block","margin-top":"8px"},attrs:{"slot":"title"},slot:"title"},[_c('div',{staticStyle:{"font-size":"20px","text-shadow":"1px 1px 1px #878686"}},[_vm._v(" "+_vm._s(_vm.selected_booking.event.name.split(",")[0])+" ")]),_c('div',{staticStyle:{"text-shadow":"1px 1px 2px #343434","font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.formatText(_vm.selected_booking.event.name))+" ")]),_c('country-flag',{staticClass:"upcoming-pop-up-flag",staticStyle:{"transform":"scale(0.8)"},attrs:{"size":"normal","country":_vm.selected_booking.event_region === 'uk'
                  ? 'gb'
                  : _vm.selected_booking.event_region}})],1)]),_c('div',{staticStyle:{"padding":"14px","position":"relative","border-bottom":"1px solid rgb(181, 229, 255, 0.2)"}},[_c('el-avatar',{staticStyle:{"position":"absolute","top":"0","right":"50%","transform":"translate(50%, -50%)"},attrs:{"size":130,"fit":"cover","src":require(`@/assets/images/speakers/${_vm.selected_booking.event.speaker.avatar}`)}}),_c('div',{staticStyle:{"text-align":"center","padding-top":"3rem","font-size":"12px"}},[_c('p',{staticClass:"speaker--name"},[_vm._v(" "+_vm._s(_vm.selected_booking.event.speaker.name)+" ")]),_c('p',{staticClass:"gray-text"},[_vm._v(" "+_vm._s(_vm.getFormatedLocalTime)+" ")]),_c('el-link',{staticStyle:{"width":"100%","margin-top":"10px","display":"block"},attrs:{"href":_vm.can_join_booking ? _vm.selected_booking.join_url : '#',"target":"_blank"}},[_c('el-button',{class:{ 'btn-success-custom': _vm.can_join_booking },staticStyle:{"width":"100%"},attrs:{"type":"success","disabled":!_vm.can_join_booking},on:{"click":function($event){_vm.can_join_booking ? _vm.setJoinedCookie(_vm.selected_booking) : ''}}},[_vm._v(_vm._s(_vm.getCountdownDate))])],1)],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }